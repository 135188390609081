import React, { useEffect, useState } from "react";
import { CircularProgress, Backdrop } from "@mui/material";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import { makeStyles } from "@mui/styles";

import clsx from "clsx";
import Sidebar from "./Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { privateApiGET } from "../../components/PrivateRoute";
import Api from "../../components/Api";
import {
  disableInitialLoading,
  setProfileData,
  setMenuAccessList,
  setMenuSelected,
} from "../../redux/app/appSlice";
import { convertToLabel } from "../../utils";
import { setChannelAccessList } from "../../redux/query/querySlice";
import CustomDialog from "../../components/CustomDialog";

const useStyles = makeStyles((theme) => ({
  mainSection: {
    padding: "85px 20px 110px 310px",
    transition: "padding 225ms cubic-bezier(0, 0, 0.2, 1) 0ms",
    "&.noSidebar": {
      [theme.breakpoints.up("md")]: {
        paddingLeft: "20px",
      },
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "15px !important",
      paddingRight: "15px !important",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    background: "#fff",
  },
}));

const convertArrayAsMenuItems = (data, isQueryMode) => {
  let tempArr = [];

  tempArr = data.map((item) => {
    return {
      id: item,
      title: convertToLabel(item),
      is_query_mode: isQueryMode,
    };
  });

  return tempArr;
};

const MainLayout = ({}) => {
  const { isSidebarOpened, initialAppLoading } = useSelector(
    (state) => state.app
  );
  const [showDialog, setShowDialog] = useState(false);
  const dispatch = useDispatch();

  // Profile API comes here & initial loading and settting up pages happen here
  const handleFetchProfileData = () => {
    privateApiGET(Api.profile)
      .then((response) => {
        const { data, status } = response;
        // console.log("Profile Data ---> ", data);
        if (status === 200) {
          dispatch(setProfileData(data?.data));

          // Check modes and pages enabled for user
          const modes = data?.data?.features?.bot_query_modes || [];
          const pages = data?.data?.features?.pages || [];
          let ModeItems = [],
            PageItems = [];

          // convert 'Modes' into menu items
          if (modes.length) {
            ModeItems = convertArrayAsMenuItems(modes, true);
            dispatch(setChannelAccessList(ModeItems));
          } else {
            console.log("No query modes enabled! Please contact admin.");
            setShowDialog(true);
            dispatch(disableInitialLoading()); // Remove initial app loading
            return;
          }

          // convert 'Pages' into menu items
          if (pages.length) {
            PageItems = convertArrayAsMenuItems(pages, false);
          }

          // Combine query modes & pages as sidebar menus
          const MenuItems = [...ModeItems, ...PageItems];
          dispatch(setMenuAccessList(MenuItems));
          dispatch(setMenuSelected(MenuItems[0]));
          dispatch(disableInitialLoading()); // Remove initial app loading
        } else {
          handleFetchProfileError();
        }
      })
      .catch((error) => {
        console.log("Profile API Error ----> ", error);
        handleFetchProfileError(error);
      });
  };

  // Handle Profile Error
  const handleFetchProfileError = (error) => {
    const { status } = error?.response || {};
    dispatch(disableInitialLoading());
    localStorage.clear();
    dispatch({ type: "LOGOUT" });
  };

  useEffect(() => {
    handleFetchProfileData();
  }, []);

  const classes = useStyles();
  return (
    <>
      <Backdrop open={initialAppLoading}>
        <CircularProgress color="primary" />
      </Backdrop>

      {showDialog && (
        <CustomDialog
          open={showDialog}
          title={"No query modes are currently enabled!"}
          content={"Please reach out to the admin for access."}
          actionButtonText={"Contact Admin"}
          // handleActionClick={handleRefreshPage}
        />
      )}

      {!initialAppLoading && (
        <div className={classes.content}>
          <Navbar />
          <Sidebar />

          <div
            className={clsx({
              [classes.mainSection]: true,
              noSidebar: !isSidebarOpened,
            })}
          >
            <Outlet />
          </div>
        </div>
      )}
    </>
  );
};

export default MainLayout;
