import { createSlice, current } from "@reduxjs/toolkit";
import {
  convertUTCTimeToLocal,
  getLoadingBubbleObj,
} from "../../components/Chat/utils";
import CHAT_MSG from "../../mock-adapter/chatMessages.json";

const initialState = {
  welcomeMessage: {},
  // userQuery: "",
  // chatMessages: [],
  // chatSessionID: "",
  // chatHisory: [],
  // bubbleInputsData: {},
  // isChannelCreating: false,
  isShowLoadHistory: true,
  isHideChatForm: false,
  channelCreationStatus: {
    isCreating: false,
    error: null,
  },
  channelAccessList: [],
  channelSelected: {},
  channelMessages: {},
  channelHistory: {},
};

const querySlice = createSlice({
  name: "query",
  initialState,
  reducers: {
    // setIsChannelCreating: (state, { payload }) => {
    //   state.isChannelCreating = payload;
    // },
    // setUserQuery: (state, { payload }) => {
    //   state.userQuery = payload;
    // },
    setChannelCreationStatus: (state, { payload }) => {
      const { isCreating, error } = payload;
      state.channelCreationStatus = {
        isCreating,
        error,
      };
    },
    setChannelMessage: (state, { payload }) => {
      const { data, id, channel_id } = payload;

      if (!state.channelMessages[channel_id]) {
        state.channelMessages[channel_id] = [];
      }

      if (id === null) {
        state.channelMessages[channel_id].push(data);
      } else {
        const { human, bot } = data;
        const index = state.channelMessages[channel_id].findIndex(
          (obj) => obj.id === id
        );

        if (index < 0) {
          state.channelMessages[channel_id].push(human, bot);
        } else state.channelMessages[channel_id].splice(index, 1, human, bot);
      }
    },
    loadChatHistory: (state, { payload }) => {
      const { data, channel_id } = payload;
      let tempArr = [];
      data.map((obj) => {
        let humanBubble = {},
          botBubble = {};
        humanBubble = {
          author: "human",
          body: obj["question"],
          date_time: convertUTCTimeToLocal(obj["question_received_timestamp"]),
          id: obj["question_id"],
          type: obj["question_type"],
        };
        botBubble = {
          ...obj.data,
          actions: [],
          author: "bot",
          date_time: convertUTCTimeToLocal(obj["response_received_timestamp"]),
        };
        tempArr.push(humanBubble, botBubble);
      });
      state.channelMessages[channel_id].splice(1, 0, ...tempArr);
    },
    setBubbleLoading: (state, { payload }) => {
      const { channel_id } = payload;
      const loadingObj = getLoadingBubbleObj();
      state.channelMessages[channel_id].push(loadingObj);
    },
    removeBubbleLoading: (state, { payload }) => {
      const { question_id, channel_id } = payload;
      const loadingIndex = state.channelMessages[channel_id].findIndex(
        (obj) => obj.id === question_id
      );

      if (loadingIndex > 0) {
        state.channelMessages[channel_id].splice(loadingIndex + 1, 1);
        return;
      }
    },
    clearActionButtons: (state, { payload }) => {
      const { channel_id } = payload;
      state.channelMessages[channel_id][
        state.channelMessages[channel_id].length - 1
      ]["actions"] = [];
    },
    setChannelInfo: (state, { payload }) => {
      const { data, id } = payload;
      const { chat_session_id, chat, initial_message } = data;
      const formattedData = JSON.parse(initial_message);

      let msgObj = {
        author: "bot",
        ...formattedData,
      };
      //state.channelSelected = { id, channel_session_id: chat_session_id };
      state.channelHistory[chat_session_id] = chat;
      // Add channel_session_id to created channel
      const channel = state.channelAccessList.find(
        (channel) => channel.id === id
      );
      if (channel) {
        channel.channel_session_id = chat_session_id;
      }

      state.welcomeMessage = {
        ...state.welcomeMessage,
        [chat_session_id]: msgObj,
      };
    },
    setChannelSelected: (state, { payload }) => {
      state.channelSelected = payload;
    },
    hideChatHistoryButton: (state) => {
      state.isShowLoadHistory = false;
    },
    // setBubbleInputsData: (state, { payload }) => {
    //   state.bubbleInputsData = { ...state.bubbleInputsData, ...payload };
    // },
    clearBubbleInputs: (state, { payload }) => {
      const { channel_id } = payload;
      state.channelMessages[channel_id][
        state.channelMessages[channel_id].length - 1
      ]["body"]["input_data"] = {};

      // state.chatMessages[state.chatMessages.length - 1]["body"]["input_data"] =
      //   {};
      // state.bubbleInputsData = {};
    },
    setChatFormHide: (state, { payload }) => {
      state.isHideChatForm = payload;
    },
    setChannelAccessList: (state, { payload }) => {
      state.channelAccessList = payload;
    },
  },
});

export const {
  setChannelCreationStatus,
  //setIsChannelCreating,
  //setUserQuery,
  setChannelMessage,
  loadChatHistory,
  setBubbleLoading,
  removeBubbleLoading,
  clearActionButtons,
  setChannelInfo,
  hideChatHistoryButton,
  //setBubbleInputsData,
  clearBubbleInputs,
  setChatFormHide,
  setChannelAccessList,
  setChannelSelected,
} = querySlice.actions;
export default querySlice.reducer;
