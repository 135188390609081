import React from "react";
import { useNavigate } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import config from "../../config";

//Material UI
import {
  AppBar,
  Container,
  Toolbar,
  IconButton,
  Badge,
  Button,
} from "@mui/material";
import NavLogo from "../MainLayout/Navbar/NavLogo";

const Header = () => {
  const navigate = useNavigate();
  const { defaultPath } = config;

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: "transparent",
        padding: "7px 0",
        borderBottom: "1px solid #3e3e3e",
        marginBottom: "25px",
      }}
    >
      <Container maxWidth="xl">
        <Toolbar sx={{ px: "0 !important" }}>
          <NavLogo width={150} />
          <div style={{ marginLeft: "auto" }}>
            {/* <Button onClick={() => navigate("/")} startIcon={<HomeIcon />}>
              Home
            </Button> */}

            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="inherit"
              onClick={() => navigate(defaultPath)}
            >
              <Badge color="success" variant="dot">
                <HomeIcon />
              </Badge>
            </IconButton>
          </div>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
