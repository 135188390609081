import React from "react";
import { useNavigate } from "react-router-dom";
import { ButtonBase } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { setMenuSelected } from "../../../redux/app/appSlice";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  listSelected: {
    backgroundColor: "#3a3a3a !important",
    //color: "inherit !important",
  },
}));

const FeatureItem = ({ itemObj }) => {
  const { menuSelected } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, url, title } = itemObj;

  const classes = useStyles();
  return (
    <ButtonBase
      onClick={() => {
        dispatch(setMenuSelected(itemObj));
        //navigate(url);
      }}
      className={id === menuSelected.id ? classes.listSelected : ""}
    >
      {title}
    </ButtonBase>
  );
};

export default FeatureItem;
