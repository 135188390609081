import { createSlice } from "@reduxjs/toolkit";
import config from "../../config";

const initialState = {
  initialAppLoading: true,
  isSidebarOpened: false,
  activePageID: config.defaultPathID,
  profileData: {},
  isBotSettingLoading: true,
  botSettingsData: {},
  menuAccessList: [],
  menuSelected: {},
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setSiderbarOpened: (state, { payload }) => {
      state.isSidebarOpened = payload;
    },
    setActivePageID: (state, { payload }) => {
      state.activePageID = payload;
    },
    disableInitialLoading: (state) => {
      state.initialAppLoading = false;
    },
    setProfileData: (state, { payload }) => {
      state.profileData = payload;
    },
    setBotSettingsData: (state, { payload }) => {
      state.botSettingsData = payload;
    },
    setBotSettingsLoading: (state, { payload }) => {
      state.isBotSettingLoading = payload;
    },
    setMenuAccessList: (state, { payload }) => {
      state.menuAccessList = payload;
    },
    setMenuSelected: (state, { payload }) => {
      state.menuSelected = payload;
    },
  },
});

export const {
  setSiderbarOpened,
  setActivePageID,
  disableInitialLoading,
  setProfileData,
  setBotSettingsData,
  setBotSettingsLoading,
  setMenuAccessList,
  setMenuSelected,
} = appSlice.actions;
export default appSlice.reducer;
