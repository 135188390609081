import React, { useEffect, useState } from "react";

import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import { useLocation } from "react-router-dom";

// defaultTheme
import theme from "./theme";

// routing
import Routes from "./routes";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setBotSettingsData } from "./redux/app/appSlice";
import SettingsLoader from "./SettingsLoader";
import Api from "./components/Api";
import { getLocalStorageItem, setLocalStorageItem } from "./utils";
import { defaultBotSettings } from "./constants";

const App = () => {
  const botSettings = getLocalStorageItem("bot_settings");
  const { isBotSettingLoading } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const location = useLocation();

  const handleFetchBotSettings = () => {
    axios({
      method: "GET",
      url: Api.botSettings,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        const { status, data } = response;
        if (status == 200) {
          // console.log("Fetch BOT Settings Response ---->", data);
          const dataObj = data?.data;
          setTimeout(() => {
            dispatch(setBotSettingsData(dataObj));
            setLocalStorageItem("bot_settings", dataObj);
          }, 1000);
        }
      })
      .catch((error) => {
        dispatch(setBotSettingsData(defaultBotSettings));
        setLocalStorageItem("bot_settings", defaultBotSettings);
        console.log("Fetch BOT Settings ERROR ---->", error);
      });
  };

  useEffect(() => {
    if (botSettings === "undefined" || botSettings == null) {
      handleFetchBotSettings();
    } else {
      dispatch(setBotSettingsData(botSettings));
    }
  }, [isBotSettingLoading]);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const authToken = urlParams.get("authToken");

    if (authToken) {
      localStorage.setItem("token", authToken);
    }
  }, [location]);

  return (
    <>
      {botSettings === "undefined" || botSettings == null ? (
        <SettingsLoader />
      ) : (
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme()}>
            <CssBaseline />
            <Routes />
          </ThemeProvider>
        </StyledEngineProvider>
      )}
    </>
  );
};

export default App;
