import React from "react";
import config from "../../../config";

//Material UI
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  useTheme,
  Badge,
} from "@mui/material";

import { Menu as MenuIcon, Logout, AccountCircle } from "@mui/icons-material";
import NavLogo from "./NavLogo";
import { useDispatch, useSelector } from "react-redux";
import { setSiderbarOpened } from "../../../redux/app/appSlice";
import { privateApiPOST } from "../../../components/PrivateRoute";
import Api from "../../../components/Api";

const Navbar = () => {
  const { isSidebarOpened, profileData } = useSelector((state) => state.app);
  const { user_basic_info } = profileData;
  const dispatch = useDispatch();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // User Logout
  const handleUserLogout = () => {
    privateApiPOST(Api.logout)
      .then((res) => {
        console.log("Logout API response ---> ", res);
        handleLogoutRedirect();
      })
      .catch((error) => {
        handleLogoutRedirect();
      });
  };

  // Handle Logout Error
  const handleLogoutRedirect = (error) => {
    localStorage.clear();
    dispatch({ type: "LOGOUT" });
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        background: theme.palette.grey["800"],
        borderBottom: `1px solid ${config.borderColor}`,
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Toolbar variant="dense">
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
          onClick={() => dispatch(setSiderbarOpened(!isSidebarOpened))}
        >
          <MenuIcon />
        </IconButton>
        <NavLogo />
        <div style={{ marginLeft: "auto" }}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <Badge color="success" variant="dot">
              <AccountCircle />
            </Badge>
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                minWidth: "160px",
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem disableTouchRipple>Hi, {user_basic_info?.name}</MenuItem>
            {/* <MenuItem onClick={handleClose}>Profile</MenuItem> */}
            {/* <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <Settings fontSize="small" />
              </ListItemIcon>
              Settings
            </MenuItem> */}
            <MenuItem onClick={handleUserLogout}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
