import PropTypes from "prop-types";
import React, { useEffect } from "react";
import config from "../../config";
import { CircularProgress, Backdrop } from "@mui/material";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { disableInitialLoading } from "../../redux/app/appSlice";
import { filterMenuItemsByModes } from "../../menu-items";
import Header from "./Header";

const MinimalLayout = () => {
  const { initialAppLoading } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(disableInitialLoading(false));

    const modes = localStorage.getItem("QueryModes");
    const modesArr = filterMenuItemsByModes(JSON.parse(modes));
    const { pathname } = location;
    const firstModeUrl = modesArr.length ? modesArr[0].url : null;
    const modeExists = modesArr.some((item) => item.url === pathname);

    // User access unathurozied page
    if (!modeExists) {
      console.log("Unauthorized Page!");
      const redirectUrl = firstModeUrl || config.defaultPath;
      navigate(redirectUrl);
      return;
    }
  }, []);
  return (
    <>
      <Backdrop open={initialAppLoading}>
        <CircularProgress color="primary" />
      </Backdrop>

      {!initialAppLoading && (
        <div>
          <Header />
          <Outlet />
        </div>
      )}
    </>
  );
};

MinimalLayout.propTypes = {
  children: PropTypes.node,
};

export default MinimalLayout;
