import moment from "moment";

export const getMessageSentTime = (date) => {
  return moment(date).format("lll");
};

export const getCurrentDateTime = () => {
  return moment().format("MMM D YYYY LTS");
};

export const getHumanBubbleObj = (query, date, id, type = "text") => {
  return {
    author: "human",
    body: query ? query : "",
    id,
    type,
    date_time: date,
  };
};

export const getLoadingBubbleObj = () => {
  return {
    author: "bot",
    type: "loading",
  };
};

export const convertUTCTimeToLocal = (date_time) => {
  return moment.parseZone(date_time).local().format("MMM D YYYY LTS");
};

export const convertInputsDataToObj = (value, label) => {
  let tempObj = {};
  tempObj[label] = value;
  return tempObj;
};
