export const themeColors = {
  white: "#ffffff",
  paper: "#454545", //4a4a4a
  darkPaper: "#212121",

  // Primary
  primaryLight: "#fff8e1",
  primaryMain: "#fe9d0b",
  primaryDark: "#fd6c0c",
  primary200: "#ffde82",
  primary800: "#fe8c0b",

  // Secondary
  secondaryLight: "#fce8e7",
  secondaryMain: "#ee3518",
  secondaryDark: "#c71a07",
  secondary200: "#ffa690",
  secondary800: "#e02c13",

  // Success
  successLight: "#81c784",
  successMain: "#2e7d32",
  successDark: "#1b5e20",
  success200: "#a5d6a7",
  success800: "#859303",

  // Error
  errorLight: "#ef9a9a",
  errorMain: "#f44336",
  errorDark: "#c62828",

  // orange
  orangeLight: "#fbe9e7",
  orangeMain: "#ffab91",
  orangeDark: "#d84315",

  // warning
  warningLight: "#fff2bd",
  warningMain: "#ffe57f",
  warningDark: "#ffc107",

  // Info
  infoLight: "#81d4fa",
  infoMain: "#29b6f6",
  infoDark: "#01579b",

  // Grey
  grey50: "#fafafa",
  grey100: "#f5f5f5",
  grey200: "#eeeeee",
  grey300: "#e0e0e0",
  grey400: "#bdbdbd", //#d9d9d9
  grey500: "#9e9e9e",
  grey600: "#757575",
  grey700: "#3e3e3e",
  grey800: "#2c2c2c",
  grey900: "#212121",
  greyA100: "#353535",
};
