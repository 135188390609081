import React from "react";
import { ButtonBase } from "@mui/material";
import { useSelector } from "react-redux";

const NavLogo = ({ width }) => {
  const { botSettingsData } = useSelector((state) => state.app);
  const { logo, logo_title } = botSettingsData;
  return (
    <ButtonBase
      disableRipple
      // onClick={() => openInNewTab("https://www.ongil.ai/")}
    >
      <img
        src={logo ? logo["files"] : ""}
        alt={logo_title ? logo_title["value"] : "Logo"}
        style={{ maxHeight: "30px", width: "100%" }}
      />
    </ButtonBase>
  );
};

NavLogo.defaultProps = {
  width: 100,
};

export default NavLogo;
