import React from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import config from "./config";

const useStyles = makeStyles((theme) => ({
  settingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: config.backgroundColor,
    fontFamily: config.fontFamily,
    color: "#d5d5d5",
    minHeight: "100vh",
  },
}));

const SettingsLoader = () => {
  const classes = useStyles();
  return (
    <Box className={classes.settingContainer}>
      <CircularProgress
        sx={{
          color: "#fe9d0b",
          mb: 3,
        }}
        size={35}
        thickness={4}
      />
      <Typography
        sx={{
          fontFamily: config.fontFamily,
          fontWeight: 500,
          letterSpacing: "0.5px",
        }}
        component="div"
      >
        Please wait..Setting up
      </Typography>
    </Box>
  );
};

export default SettingsLoader;
